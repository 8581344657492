<template>
  <SectionWithBg
    heading-position="left"
    class="sponsor-acquire"
    id="sponsor-acquire"
  >
    <template #heading>
      <div class="sponsor-acquire__title">Do you want to join us?</div>
    </template>
    <template #body>
      <div class="sponsor-acquire__body">
        <transition name="fade">
          <FormSuccess v-if="isFormSent">
            Thank you for applying! We will contact you as soon as possible!
          </FormSuccess>
        </transition>
        <transition name="fade">
          <form
            v-if="!isFormSent"
            @submit.prevent="sendSponsorshipForm"
            class="sponsor-acquire__form"
          >
            <div
              v-for="(field, name) in form"
              :key="name"
              class="sponsor-acquire__input-container"
            >
              <Input
                v-model="field.value"
                :error="field.error"
                :name="`sponsor-acquire-${name}`"
                :label="field.label"
                type="text"
                @input="clearError(name, form)"
                @blur="validateField(name, form)"
              />
              <div
                v-if="!field.rules.includes('required')"
                class="sponsor-acquire__optional"
              >
                Optional
              </div>
            </div>
            <Button class="sponsor-acquire__button mb-2">
              become a sponsor
            </Button>
            <transition name="fade">
              <Error v-if="hasError">
                <template #heading> Something went wrong </template>
                <template #body>
                  Please try again or contact us at
                  <a :href="`mailto:${config.helpEmail}`" class="error__link">
                    {{ config.helpEmail }}
                  </a>
                </template>
              </Error>
            </transition>
          </form>
        </transition>
      </div>
    </template>
  </SectionWithBg>
</template>

<script>
import { validateField, clearError } from "@/assets/js/validation";
import SectionWithBg from "@/components/common/SectionWithBg";
import Input from "@/components/common/Input";
import Button from "@/components/common/Button";
import FormSuccess from "@/components/common/FormSuccess";
import Error from "@/components/common/Error";
import { reactive } from "vue";
import useSqsFormSend from "@/assets/js/composables/sqsFormSend";
import config from "../../../public/config.json";

export default {
  name: "SponsorAcquire",
  props: {},
  components: {
    SectionWithBg,
    Input,
    Button,
    FormSuccess,
    Error,
  },

  setup() {
    const form = reactive({
      name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Name",
      },
      company: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Company",
      },
      email: {
        value: "",
        error: "",
        rules: ["required", "email"],
        label: "Email",
      },
    });

    const { isFormSent, hasError, sendForm } = useSqsFormSend();

    const sendSponsorshipForm = () => {
      sendForm(form, "sponsorship");
    };

    return {
      config,
      form,
      isFormSent,
      hasError,
      sendSponsorshipForm,
      validateField,
      clearError,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.sponsor-acquire {
  flex-direction: column;

  &__input-container {
    position: relative;
  }

  &__optional {
    position: absolute;
    right: 0;
    bottom: -4px;
    font-size: 0.9rem;
    color: $gray;
  }

  &__button {
    width: 100%;
  }

  &__title {
    font-family: $Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    max-width: 100%;
  }

  .bg-section__heading {
    max-width: unset !important;
  }

  .bg-section__text {
    padding-top: 60px !important;
  }

  @media (min-width: $media-sm) {
    &__title {
      font-size: 72px;
      line-height: 107px;
      letter-spacing: 0.05em;
    }

    &__body {
      min-width: 380px;
      max-width: 380px;
    }
  }

  @media (min-width: $media-md) {
    &__body {
      min-width: 480px;
      max-width: 480px;
    }
  }
}
</style>
