<template>
  <div class="sponsor">
    <SponsorHero :eventName="event.name" :eventDate="event.start" />
    <SponsorAcquire />
    <!-- <SectionWithBg heading-position="right">
      <template #heading> Sponsoring</template>
      <template #body>
        Exploit attracts an audience of technologists, business leaders and
        industry experts leading the digital revolution. As a sponsor, you’ll
        have the opportunity to establish your company as the go-to brand to
        this elite community of influencers and change makers.
        <div v-if="sponsors" class="sponsor__list">
          <a
            v-for="sponsor in sponsors"
            v-bind:key="sponsor.id"
            :href="sponsor.website"
          >
            <img
              :src="sponsor.logo"
              alt="sponsor logo"
              class="sponsor__list-item"
            />
          </a>
        </div> -->
    <!--        <div class="sponsor__list">
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                          <a href="#">
                            <img
                              src="@/assets/img/sponsor-sample.png"
                              alt="sponsor logo"
                              class="sponsor__list-item"
                            />
                          </a>
                        </div>-->
    <!-- </template> -->
    <!-- </SectionWithBg> -->
    <SponsorAbout :sponsors="sponsors" v-if="sponsors" />
    <div style="height: 135px"></div>
    <!--    <SponsorApply />-->
    <SponsorGallery />
    <SponsorFAQ />
    <ContactUs />
  </div>
</template>

<script>
import SponsorAcquire from "@/components/home/SponsorAcquire";
import SponsorHero from "./SponsorHero";
import SponsorGallery from "./SponsorGallery";
import SponsorFAQ from "./SponsorFAQ";
import ContactUs from "../event/ContactUs";
import SponsorAbout from "./SponsorAbout";
import { mapActions, mapState } from "vuex";
// import SponsorApply from "./SponsorApply";

export default {
  name: "SponsorPage",
  props: {},
  components: {
    // SponsorApply,
    ContactUs,
    SponsorFAQ,
    SponsorGallery,
    SponsorAbout,
    SponsorHero,
    SponsorAcquire,
  },
  data() {
    return {
      isSponsorsLoading: false,
      sponsors: null,
      event: {},
      isEventLoading: false,
    };
  },
  computed: {
    ...mapState(["currentEventID"]),
  },
  mounted() {
    this.isSponsorsLoading = true;
    this.isEventLoading = true;
    this.getSponsors();
    this.getCurrentEvent();
  },
  methods: {
    ...mapActions(["GET", "getEvent"]),
    getSponsors() {
      return this.GET({
        route: `public/event/${
          this.$route.params.id || this.currentEventID
        }/sponsorship`,
      })
        .then((resp) => {
          console.log("getSponsors", resp.data);
          this.sponsors = resp.data;
          this.isSponsorsLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isSponsorsLoading = false;
        });
    },

    getCurrentEvent() {
      return this.getEvent(this.$route.params.id)
        .then((resp) => {
          this.event = resp;
          this.isEventLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isEventLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.sponsor {
  $self: &;

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;

    a {
      display: flex;
      padding: 2px;
      width: 92px;
      height: 92px;
    }

    &-item {
      /*      filter: grayscale(100%);
                        -webkit-filter: grayscale(100%); !* Safari 6.0 - 9.0 *!
                        -moz-filter: grayscale(100%);
                        -o-filter: grayscale(100%);*/
      width: 100%;
      object-fit: contain;
      object-position: center;
      padding-right: 16px;
      padding-bottom: 20px;
    }
  }

  @media (min-width: $media-md) {
    &__list {
      &-item {
        max-width: 198px;
        /*width: 100%;*/
        /*height: 55px;*/
        padding-right: 24px;
        padding-bottom: 30px;
      }
    }
  }

  @media (min-width: $media-lg) {
    &__list {
      margin-top: 32px;

      &-item {
        padding: 30px 24px 0 0;
        /*width: 72px;*/
        /*height: 72px;*/
      }
    }
    @media (min-width: $media-xl) {
      &__list a {
        width: 200px;
        height: 200px;
      }
    }
  }
}
</style>
