<template>
  <div class="faq padding-x">
    <Heading noDecoration text-align="center" class="faq__header">
      FAQ's
    </Heading>
    <div
      v-for="(item, index) in faqList"
      :key="`item-${index}`"
      class="faq__item"
    >
      <div class="faq__item-num">{{ index + 1 }}</div>
      <div class="faq__item-container">
        <div class="faq__item-title">{{ item.title }}</div>
        <div class="faq__item-description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "../common/Heading";
export default {
  name: "SponsorFAQ",
  props: {},
  components: { Heading },
  data: () => ({
    faqList: [
      {
        title: "Can I purchase tickets for my group for the ExploitCon event?",
        description:
          "Yes, you can purchase tickets for your group. You can go through the normal purchasing process that you do when you purchase tickets for yourself. Simply increase the quantity of tickets that you want to purchase and send the registration code to your group so that they can all register for the event.",
      },
      {
        title: "Can I cancel my ExploitCon ticket?",
        description:
          "Yes, ExploitCon tickets are cancellable. To cancel your ticket, please contact our support staff at info@exploitcon.com and tell them what your Ticket ID is.",
      },
      {
        title: "Can I transfer my ticket?",
        description:
          "Yes, ExploitCon tickets are transferrable. To transfer your ticket to another potential participant, please contact our support staff at info@exploitcon.com and present the following information about the first name, last name, and the email address of the person you will transfer the ticket to. Please also mention what your Ticket ID is.",
      },
      {
        title: "What will I expect in an ExploitCon seminar? ",
        description:
          "ExploitCon seminars offer you different hands-on and experiential activities that will help you understand the world of cybersecurity. You will be led to different villages, which are different learning areas that correspond to a specific skill that you might be interested to explore.",
      },
      // {
      //   title: "How can I be accepted to become a speaker for your talk?",
      //   description: "If you want to be a speaker at ____, please submit a proposal for your talk using the link below _______. Please note that there are 3 rounds of acceptance that are being followed before you will be accepted as a speaker. Our team will reach out to you once your talk is approved. If you want to check the status of your application, please contact us anytime at info@exploitcon.com.",
      // },
      // {
      //   title:
      //     "As a speaker, is there any template that I should use for my talks?",
      //   description:
      //     "No, there is no specific template to be used during talks. However, there are guidelines that should be followed. For example, speakers should remove their company logo in their presentation.",
      // },
      {
        title:
          "Do you provide free hotel accommodations for your sponsors and speakers?",
        description:
          "No, we do not provide hotel accommodations for our sponsors and speakers. However, we provide our sponsors and speakers with a list of hotels that offer discounted rates during their stay. Once sponsors receive their exhibitor kit, they will be provided with a link that shows the list of hotels with discounted rates. The protocol for speakers is the same, once speakers are confirmed they will receive their information in a speaker kit from us.",
      },
      {
        title: "Will I receive a certificate of attendance?",
        description:
          "Yes, all participants will receive a certificate of attendance at the culmination of the program.",
      },
    ],
  }),
  computed: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.faq {
  padding-top: 20px;
  $self: &;

  &__header {
    padding-bottom: 30px;
  }

  &__item {
    position: relative;
    padding-bottom: 40px;
    display: flex;

    &-container {
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-sizing: border#ffffff33;
      border-radius: 10px;
      padding: 24px;
      position: relative;
      width: 100%;
    }

    &-num {
      font-family: $Oswald;
      font-weight: bold;
      font-size: 72px;
      line-height: 107px;
      letter-spacing: 0.05em;
      color: $light-gray;
      margin-right: 24px;
      z-index: -1;
      position: absolute;
      top: -15%;
      color: #ffffff33;
    }

    &-title {
      font-family: $Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 36px;
      letter-spacing: 0.05em;
      position: relative;
      padding-bottom: 24px;
      text-transform: uppercase;

      &::after {
        content: "";
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &-description {
      font-family: $Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: rgba(255, 255, 255, 0.8);
      padding-top: 24px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 100px;

    &__header {
      padding-bottom: 70px;
    }
    &__item {
      &-num {
        position: static;
      }
      &-num {
        position: static;
      }
      &-title {
        font-size: 24px;
      }
      &-description {
        font-size: 18px;
      }
    }
  }
  @media (min-width: $media-lg) {
    &__header {
      padding-bottom: 95px;
    }
  }
}
</style>
