<template>
  <section class="hero padding-x">
    <div
      class="hero__image"
      :style="`background-image: url('${
        image || require('@/assets/img/hero/workshop.png')
      }');`"
    ></div>
    <div class="hero__image--overlay"></div>

    <div class="hero__content">
      <Heading type="h1">
        {{ title }}
      </Heading>
      <p v-if="tagline">{{ tagline }}</p>
    </div>
    <slot name="content"></slot>
    <slot name="button"> </slot>
  </section>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  components: {
    Heading,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    tagline: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables";
.hero {
  min-height: 792px;
  padding-top: 120px;

  &__image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
    max-height: 792px;
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    top: 0;

    &::after {
      content: "";
      width: 100vw;
      height: 100%;
      max-height: 792px;
      background: linear-gradient(180deg, rgba(9, 9, 9, 0.5) 0%, #090909 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  &__content {
    h1 {
      font-weight: 600;
      font-size: 48px;
      letter-spacing: 1px;
      max-width: 920px;
      margin-bottom: 24px;
    }

    p {
      font-family: $Lato;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
    }
  }

  &__action {
    margin-top: 60px;
  }
}

@media (min-width: $media-sm) {
  .hero {
    height: 792px;
    padding-top: 260px;
    margin-bottom: 200px;

    &__image {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100%;
      max-height: 792px;
      position: absolute;
      z-index: -2;
      left: 0;
      right: 0;
      top: 0;
      &::after {
        content: "";
        width: 100vw;
        height: 100%;
        max-height: 792px;
        background: linear-gradient(
          180deg,
          rgba(9, 9, 9, 0.5) 0%,
          #090909 100%
        );
        transform: matrix(-1, 0, 0, 1, 0, 0);
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
      }
    }

    &__content {
      h1 {
        font-weight: 600;
        font-size: 72px;
        line-height: 107px;
        letter-spacing: 0.05em;
        max-width: 920px;
        margin-bottom: 24px;
      }

      p {
        font-family: $Lato;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.05em;
      }
    }
  }
}
</style>
