<template>
  <div class="sponsor-about">
    <div class="sponsor-about__wrap padding-x">
      <div class="sponsor-about__content">
        <Heading type="h3"> Sponsoring </Heading>
        <p>
          ExploitCon attracts an audience of technologists, business leaders and
          industry experts leading the digital revolution. As a sponsor, you’ll
          have the opportunity to establish your company as the go-to brand to
          this elite community of influencers and change makers.
        </p>
      </div>

      <div class="sponsor-about__images">
        <div class="images-wrapper">
          <Splide :options="splideOptions">
            <SplideSlide
              v-for="(value, index) in Math.ceil(sponsors.length / 4)"
              :key="index"
            >
              <div class="sponsors-logo">
                <div
                  class="sponsors-logo__card"
                  v-for="(sponsor, sponsorIndex) in sponsors.slice(
                    index ? index * 4 : 0,
                    (index + 1) * 4
                  )"
                  :key="`${index}--${sponsorIndex}`"
                >
                  <img :src="sponsor.logo" alt="Sponsor logo" />
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Heading,
    Splide,
    SplideSlide,
  },
  props: {
    sponsors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      splideOptions: {
        direction: "ttb",
        interval: "2500",
        type: "loop",
        wheel: true,
        autoplay: true,
        pause: false,
        pauseOnHover: true,
        height: "620px",
        arrows: false,
        breakpoints: {
          320: {
            height: "296px",
            gap: 16,
          },
          480: {
            height: "416px",
            gap: 16,
          },
          768: {
            height: "416px",
            gap: 16,
          },
          1200: {
            height: "500px",
            gap: 32,
          },
          1400: {
            height: "620px",
            gap: 32,
          },
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.sponsor-about {
  background-color: #202020;
  min-height: 628px;
  width: 100%;
  position: relative;
  margin-top: 240px;

  &__content {
    padding: 24px 0 88px 0;
    flex-basis: 50%;
    order: 2;

    h3 {
      font-family: $Oswald;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 107px;
      letter-spacing: 0.05em;
      margin-bottom: 16px;
    }

    p {
      font-family: $Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: #ffffffcc;
      padding-right: 32px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__images {
    position: relative;
    flex-basis: 50%;

    ::v-deep .splide__list {
      padding: 0 !important;
    }

    .images-wrapper {
      width: calc(280px + 16px);
      height: calc(280px + 16px);
      overflow: hidden;
      margin-top: -88px;
    }

    ::v-deep .sponsors-logo {
      display: flex;
      flex-wrap: wrap;
      max-width: calc(320px + 16px);
      row-gap: 16px;
      column-gap: 16px;

      &__card {
        background: #ffffff;
        border-radius: 10px !important;
        height: 140px;
        width: 140px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 120px;
          max-height: 120px;
        }
      }
    }
  }
}
@media (min-width: $media-xs) {
  .sponsor-about {
    background-color: #202020;
    min-height: 628px;
    width: 100%;
    position: relative;
    margin-top: 240px;

    &__content {
      padding: 24px 0 88px 0;
      flex-basis: 50%;
      order: 2;

      h3 {
        font-family: $Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 107px;
        letter-spacing: 0.05em;
        margin-bottom: 16px;
      }

      p {
        font-family: $Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.05em;
        color: #ffffffcc;
        padding-right: 32px;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    &__images {
      position: relative;
      flex-basis: 50%;

      ::v-deep .splide__list {
        padding: 0 !important;
      }

      .images-wrapper {
        width: calc(400px + 16px);
        height: calc(400px + 32px);
        overflow: hidden;
        margin-top: -88px;
      }

      ::v-deep .sponsors-logo {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(420px + 16px);
        row-gap: 16px;
        column-gap: 16px;

        &__card {
          background: #ffffff;
          border-radius: 10px !important;
          height: 200px;
          width: 200px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 160px;
            max-height: 160px;
          }
        }
      }
    }
  }
}

@media (min-width: $media-lg) {
  .sponsor-about {
    background-color: #202020;
    min-height: 628px;
    width: 100%;
    position: relative;
    margin-top: 120px;

    &__content {
      padding: 88px 0;
      flex-basis: 50%;

      h3 {
        font-family: $Oswald;
        font-style: normal;
        font-weight: 600;
        font-size: 72px;
        line-height: 107px;
        letter-spacing: 0.05em;
        margin-bottom: 48px;
      }

      p {
        font-family: $Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.05em;
        color: #ffffffcc;
        padding-right: 32px;
      }
    }

    &__wrap {
      display: flex;
      position: relative;
      flex-direction: row;
    }

    &__images {
      position: relative;
      flex-basis: 50%;
      order: 2 !important;

      ::v-deep .splide__list {
        padding: 0 !important;
      }

      .images-wrapper {
        width: calc(500px + 32px);
        height: calc(500px + 32px);
        overflow: hidden;
        position: absolute;
        right: 0;
        top: -228px;
      }

      ::v-deep .sponsors-logo {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(500px + 32px);
        row-gap: 32px;
        column-gap: 32px;

        &__card {
          background: #ffffff;
          border-radius: 10px !important;
          height: 250px;
          width: 250px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 200px;
            max-height: 200px;
          }
        }
      }
    }
  }
}
</style>
